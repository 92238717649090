import {
  Box,
  Flex,
  Text,
  Heading,
} from "@chakra-ui/react"
import styles from "../styles/404.module.css"
import Image from "next/image"
import Link from "next/link"
import bg404 from "../public/img/404.svg"
import HeroButton from "../components/buttons/HeroButton"
import { useRouter } from "next/router"
import Meta from "../components/meta/Meta"
export default function PageNotFound() {
  const router = useRouter()
  function ButtonClick() {
    router.push("/")
  }

  return (
    <>
      <Meta pageName={"Page Not Found"} />
      <Box className={styles.NotFoundContainer}>
        <Flex
          className={styles.NotFoundFlexContainer}
          direction={{ base: "column", md: "column", lg: "row" }}
        >
          <Box
            className={styles.LeftContainer}
            display={{ base: "none", md: "none", lg: "block" }}
          >
            <Image
              src={bg404}
              layout="responsive"
              alt="Not found"
            >
            </Image>
          </Box>
          <Box
            className={styles.RightContainer}
            justifyContent={{ base: "center" }}
          >
            <Heading
              as="h1"
              fontSize="7.5rem"
              margin="15px 0px"
              fontWeight="bold"
              textAlign={{ base: "center", md: "left", lg: "left" }}
            >
              404
            </Heading>
            <Heading
              as="h2"
              fontWeight="bold"
              textAlign={{ base: "center", md: "left", lg: "left" }}
            >
              {"UH OH! You're lost."}
            </Heading>
            <Text textAlign={{ base: "center", md: "left", lg: "left" }}>
              The page you are looking for does not exist. How you got here is a mystery.
              But you can click the button below to go back to the homepage.
            </Text>
            <Box
              my={15}
              flex={1}
              justifyContent={{ base: "center", md: "flex-start", lg: "flex-start" }}
              display="flex"
            >
              <Link href="/" passHref>
                <HeroButton buttonName="Go to Home Page" ButtonClick={ButtonClick} />

              </Link>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  )
}